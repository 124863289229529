<template>
  <div ref="position" class="theme1">
    <b-dropdown variant="darkOrange" size="sm" ref="dropdown" no-caret>
      <template #button-content>
        <i class="fas fa-arrow-circle-down"></i>{{ "\xa0" }}{{$t('home.more-date-click')}}
      </template>
      <b-dropdown-item v-for="(item, inx) in items" :key="inx" :href="getLink(item)">
        <div class="display-flex">
          <div class="date-range" onclick="return false;">
            <span dir="ltr">{{ changeDateFormat(item.departureDate) }} - {{ changeDateFormat(item.arrivalDate) }}</span>
          </div>
          <div class="night-box" onclick="return false;">
            <span> {{item.nights}} {{ (item.nights > 1) ? $t("home.nights"): $t("home.night")}} </span>
          </div>
          <div class="price-box" onclick="return false;">
            <span class="currency-symbol">{{item.currenySymbol}}</span><strong>{{item.packageType === "F" ? item.discountedPriceFO.priceByAge[0].price: item.discountedPrice.price_average}}</strong>
          </div>
          <div class="pl-0 pr-2 py-0">
            <b-button size="sm" variant="primary">
              <a :href="getLink(item)" v-if="isLanding" class="more-link-button">
                 {{ $t("home.slide-deal.order-now") }}
              </a>
              <router-link :to="getLink(item)" v-else class="more-link-button">
                 {{ $t("home.slide-deal.order-now") }}
              </router-link>
            </b-button>
          </div>
        </div>
      </b-dropdown-item>

    </b-dropdown>
  </div>
</template>
<script>
import {
  VBToggle,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import dayjs from 'dayjs';
import TemplateMoreDealCollapse from './TemplateMoreDealCollapse';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'MoreDealCollapseTheme1',
  mixins: [TemplateMoreDealCollapse],
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>
<style>
  .theme1.collapse-button .dropdown-toggle{
    padding: 1px 4px;
  }
  .theme1.collapse-button .dropdown-menu{
    box-shadow: grey 1px 1px 10px;
  }
  .theme1 .collapse-button .dropdown-item {
    cursor: default;
  }
  .theme1.dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: white;
  }
  .theme1.collapse-button .dropdown {
    font-size: 12px;
    text-transform: uppercase;
    background-color: #FF8B01;
    position: absolute;
    left: 25px;
    color: #fff;
    font-weight: 600;
    padding: 7px 14px;
    top: 10px;
    border-radius: 6px 0px 0px 6px;
    z-index: 5;
  }
  .theme1.collapse-button .btn-success,
  .theme1.collapse-button .btn-success:not(:disabled):not(.disabled):active,
  .theme1.collapse-button .btn-success .btn-success:focus
  {
      background-color: unset;
      border: unset;
  }
  .theme1.collapse-button .btn-success:focus{
      box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%);
  }
  .theme1.collapse-button .dropdown .dropdown-toggle {
    color: #fff;
  }
  @media (max-width: 768px) {
    .collapse-button .dropdown-item {
      padding: 0.25rem 1rem;
    }
  }
</style>
<style scoped>
  .display-flex{
    display: flex;
    text-align: right;
  }
  .date-range, .price-box {
    background-color: #313232;
    color: white;
  }
  .date-range {
    width: 180px;
    text-align-last: justify;
  }
  .price-box {
    font-size: 24px;
    /* width: 75px; */
  }
  .dropdown-item .date-range, .dropdown-item .night-box {
    padding: 0.25rem 0.8rem;
  }
  .dropdown-item .price-box {
    padding: 0;
    text-align: center;
    height: 31px;
  }
  a.more-link-button{
    color: white;
  }
  button.btn.btn-primary.btn-sm {
    background-color: #23bedc;
    border-color: #23bedc;
}
  @media (max-width: 768px) {
    .dropdown-item .date-range, .dropdown-item .price-box, .dropdown-item .night-box{
      font-size: 13px;
    }
    .date-range {
      width: 142px;
    }
    .price-box {
      font-size: 24px;
      width: 40px;
    }
    .dropdown-item .date-range, .dropdown-item .price-box, .dropdown-item .night-box {
      padding: 0.25rem 0.1rem;
      text-align: center;
    }
  }
</style>
